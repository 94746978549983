<template>
  <Animation />

  <div class="container">
    <div class="intro-title">Me töötame</div>
    <div class="intro-title">te puhkate</div>
    <router-link class="btn" to="/services">Rohkem</router-link>
  </div>
</template>
<script>
  import Animation from "./Animation.vue";
  export default {
    components: {
      Animation,
    },
  };
</script>
<style scoped>
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    overflow: hidden;
  }

  .intro-title {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.5;
    text-shadow: 3px 5px 5px #164779;
    font-size: calc(30px + 35 * ((100vw - 320px) / (1200 - 320)));
  }
  .btn {
    border: 2px solid #fff;
    display: inline-block;
    padding: 15px 30px;
    font-size: 15px;
    text-transform: uppercase;
    color: rgb(247, 247, 247);
    letter-spacing: 2px;
    background: #333;
    cursor: pointer;
    font-weight: 600;
    vertical-align: top;
    align-self: center;
    margin-top: 50px;
    transition: background 0.2s linear;
    -webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -ms-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;

    box-shadow: 3px 3px 3px #164779;
  }

  .btn:hover {
    color: #333;
    background: #22a0f3;
  }

  @media (max-width: 800px) {
    .btn {
      padding: 2vw 2vw;
    }
  }

  @media (max-width: 320px) {
    .intro-title {
      font-size: 25px;
    }

    .btn {
      padding: 10px 7px;
      font-size: 12px;
    }
  }
</style>
