import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./HomePage.vue";
import Services from "./Services.vue";
import About from "./About.vue";
import Gallery from "./Gallery.vue";
import NotFound from "./NotFound.vue";
import Board from "./views/Board.vue";
import Software from "./views/Software.vue";
import Network from "./views/Network.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/portfolio",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/contact",
    name: "About",
    component: About,
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/board",
    name: "Board",
    component: Board,
  },
  {
    path: "/software",
    name: "Software",
    component: Software,
  },
  {
    path: "/network",
    name: "Network",
    component: Network,
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
