<template>
  <div class="container">
    <div class="description-body">
      <router-link class="nav-link" to="/services"
        ><fa icon="fa-arrow-left" class="arrow" />Tagasi
      </router-link>
      <h2>Interaktiivne puutetahvel</h2>
      <p>
        Interaktiivne puutetahvel on suur puuteekraan, mis on ühendatud
        arvutiga. Projektori abil projitseeritakse arvutipilt ekraanile, kus
        kasutaja saab kujutist hallata puutepliiatsi, sõrme, stiiluse või mõne
        muu osutusvahendi abil. Tahvel on tavaliselt kinnitatud seinale või
        toetub põrandajalale.
      </p>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/a/a2/Interactive_whiteboard_at_CeBIT_2007.jpg"
      />
      <p>
        Interaktiivset puutetahvlit kasutatakse haridusasutustes, ettevõtete ja
        asutuste koosolekuruumides, professionaalsete sporditreeningute
        läbiviimisel, telestuudiotes ja mujal.
      </p>
      <p>
        2008. aastaks eeldasid interaktiivsete puutetahvlite tootjad maailma
        müügimahu kasvu 1 miljardi dollarini. 2013. aasta lõpuks on
        turu-uuringufirma Futuresource Consulting tehtud uuringu hinnangul
        pooled USA klassid varustatud interaktiivse puutetahvliga, mis aga
        tähendab müügi vähenemist, kuna vanad mudelid on veel kasutuses. Suurim
        müügikasv on 2013. aastal Hiinas, kus aasta lõpuks on interaktiivne
        puutetahvel olemas hinnanguliselt 37% klassides.
      </p>
      <p>Interaktiivse puutetahvliga saab:</p>
      <ul>
        <li>
          <p>
            kasutada tarkvara, mis on paigaldatud tahvliga ühendatud arvutisse –
            nt brauser vms;
          </p>
        </li>
        <li><p>salvestada tahvlile kirjutatud märkmeid arvutisse;</p></li>
        <li>
          <p>
            salvestada interaktiivse puutetahvliga ühendatud graafikalaual
            loodud materjale;
          </p>
        </li>
        <li>
          <p>
            juhtida arvuti käitumist tahvli abil, kasutades pukseerimis- (drop &
            drag) ja märgistusfunktsioone, millega lisatakse programmile või
            esitlusele märkmeid;
          </p>
        </li>
        <li>
          <p>
            tekstituvastustarkvara abil tõlkida graafikalaual kirjutatud teksti
            arvutile mõistetavaks;
          </p>
        </li>
        <li>
          <p>
            kasutada elektroonilise tagasiside süsteeme, millega on võimalik
            viia läbi küsitlusi ja teste ning näidata tulemusi tahvlil.
          </p>
        </li>
      </ul>
      <a title="Puutetahvel" href="https://et.wikipedia.org/wiki/Puutetahvel"
        >Rohkem infot</a
      >
      wikipediast

      <p class="portfolio-link">
        Vaata meie tööd
        <router-link to="/portfolio">siit</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.portfolio-link {
  text-align: center;
  font-size: 120%;
  font-style: italic;
  position: relative;
  font-weight: 600;
}

.portfolio-link::after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background-color: #2270be;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 1;
  z-index: 2;
  margin-top: 3px;
}

img {
  width: 80%;
  height: 50%;
  object-fit: cover;
  object-position: 50% 50%;
}
a {
  color: #1e73be;
}
.nav-link {
  font-style: italic;
  font-size: 80%;
  margin-bottom: 15px;
  padding: 5px;
  opacity: 1;
  display: block;
  width: 100px;
  font-weight: normal;
  color: #fff;
}

.nav-link::after {
  width: 80px;
  margin-top: 3px;
  margin-left: 10px;
}

h2 {
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 150%;
  color: #22a0f3;
}

p {
  text-align: start;
  padding: 1rem;
  font-style: normal;
  font-size: 110%;
  line-height: 1.5;
}

ul {
  padding-left: 5px;
  margin-left: 1em;
}

.arrow {
  margin-right: 5px;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(28, 28, 28);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #2425269e;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1f4871;
}

.description-body {
  padding: 20px;
  color: rgb(238, 231, 231);
  background-color: rgba(36, 34, 34, 0.54);
  box-shadow: 3px 3px 3px #1f4871;
  overflow-y: auto;
  height: 600px;
  position: relative;
  transition: 0.3s;
}

.description-body:hover {
  background-color: rgb(36, 34, 34);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

@media (max-width: 431px) {
  .description-body {
    padding: 3px;
  }
}
</style>
