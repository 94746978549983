<template>
  <div class="container">
    <div class="description-body">
      <router-link class="nav-link" to="/services"
        ><fa icon="fa-arrow-left" class="arrow" />Tagasi
      </router-link>
      <h1>Coming soon</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.nav-link {
  font-style: italic;
  font-size: 80%;
  margin-bottom: 15px;
  padding: 5px;
  opacity: 1;
  display: block;
  width: 100px;
  font-weight: normal;
}
.nav-link::after {
  width: 80px;
  margin-top: 3px;
  margin-left: 10px;
}

.arrow {
  margin-right: 5px;
}
</style>
