<template>
  <h2>WiFi võrkude ehitamine</h2>
  <p>
    <b>Wi-Fi</b> on traadita arvutivõrguseadmeid tootvate firmade ühenduse (<a
      class="new"
      title="Wi-Fi Alliance (pole veel kirjutatud)"
      href="https://et.wikipedia.org/w/index.php?title=Wi-Fi_Alliance&amp;action=edit&amp;redlink=1"
      >Wi-Fi Alliance</a
    >)
    <a title="Kaubamärk" href="https://et.wikipedia.org/wiki/Kaubam%C3%A4rk"
      >kaubamärk</a
    >, millega tähistatakse
    <a title="Sertifikaat" href="https://et.wikipedia.org/wiki/Sertifikaat"
      >sertifitseeritud</a
    >
    traadita kohtvõrgu (<a
      class="mw-redirect"
      title="WLAN"
      href="https://et.wikipedia.org/wiki/WLAN"
      >WLAN</a
    >) klassi kuuluvaid seadmeid, mis põhinevad
    <a title="IEEE 802.11" href="https://et.wikipedia.org/wiki/IEEE_802.11"
      >IEEE 802.11</a
    >
    standardil. Nime <i>Wi-Fi</i> kasutatakse sageli IEEE 802.11 tehnoloogia
    sünonüümina.
  </p>
  <p>
    Wi-Fi Alliance (eesti k. Wi-Fi Liit) on ülemaailmne mittetulundusühing
    firmadest, kes arendavad traadita kohtvõrgu (WLAN) tehnoloogiaid ja
    sertifitseerivad seadmeid, kui need vastavad teatud võrgustandarditele.
    Kõiki IEEE 802.11 standardil põhinevaid seadmeid ei esitata Wi-Fi Liidule
    sertifitseerimiseks, mõnikord selle maksumuse tõttu ning Wi-Fi logo
    puudumine ei tähenda, et seade on Wi-Fi-ga ühildamatu.
  </p>
  <p>
    Wi-Fi võimaldab paljudel kasutajatel Wi-Fi-levialades samaaegselt luua
    juhtmevaba ühendus Internetiga. Tänapäeval on IEEE 802.11 seadmed juba
    paljudes
    <a
      title="Personaalarvuti"
      href="https://et.wikipedia.org/wiki/Personaalarvuti"
      >personaalarvutites</a
    >,
    <a
      title="Mängukonsool"
      href="https://et.wikipedia.org/wiki/M%C3%A4ngukonsool"
      >mängukonsoolides</a
    >,
    <a title="Nutitelefon" href="https://et.wikipedia.org/wiki/Nutitelefon"
      >nutitelefonides</a
    >,
    <a title="Printer" href="https://et.wikipedia.org/wiki/Printer"
      >printerites</a
    >
    ja muudes seadmetes ning peaaegu kõigis
    <a title="Sülearvuti" href="https://et.wikipedia.org/wiki/S%C3%BClearvuti"
      >sülearvutites</a
    >
    ja
    <a title="Pihuarvuti" href="https://et.wikipedia.org/wiki/Pihuarvuti"
      >pihuarvutites</a
    >.
  </p>
  <p>
    Ehitame WiFi võrkude
    <a href="https://www.ui.com/products/#unifi">UniFi®Ubiquiti</a> seadme
    alusel. Kvaliteetne ja kaasaegne ühendus, kiirusega kuni 1700 Mb/s.
  </p>
</template>

<script>
export default {};
</script>

<style scoped>
h2 {
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 150%;
  color: #22a0f3;
}

p {
  text-align: start;
  padding: 1rem;
  font-style: normal;
  font-size: 110%;
  line-height: 1.5;
}

a {
  color: #1e73be;
}
</style>
