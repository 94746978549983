<template>
  <div class="fullscreen-coffee">
    <div class="about-block">
      <div class="square1"></div>
      <div class="square2"></div>
      <div class="container">
        <div class="about-content">
          <div class="about-content-section">
            <div class="about-title">Võta meiega <span>ühendust</span></div>
            <div class="about-image">
              <img class="about__image" src="/images/brothers.jpg" alt="" />
            </div>
          </div>
          <div class="about-contacts">
            <div class="about-body">
              <div class="firma">
                <div>J&DSmartIt OÜ</div>
                <div>Registrikood: 14645963</div>
              </div>
              <div class="phone">
                <fa icon="phone" class="phone-icon" /> +372 55 570 997
              </div>
              <div class="mail">
                <fa icon="envelope" class="envelope-icon" /> info@jdsmartit.ee
              </div>
              <div class="location">
                <fa icon="location-dot" class="location-icon" />

                <a
                  href="https://www.google.com/maps/dir//59.472617,25.0144798/@59.4724542,25.0140694,142m/data=!3m1!1e3!4m2!4m1!3e0?hl=et"
                  target="_"
                  class="location"
                >
                  Roobu tn. 6a, Maardu 74114</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
.fullscreen-coffee {
  background: url(/images/coffee.jpg) center / cover no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.about-block {
  margin: 250px 0;
  background-color: rgba(36, 34, 34, 0.647);
  box-shadow: 3px 3px 3px #1f4871;
  padding: 20px 0;
  position: relative;
  transition: 0.3s;
}

.about-block:hover {
  background-color: rgb(36, 34, 34);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

.about-content {
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.about-content .about-content-section {
  flex: 1;
  padding: 15px;
}

.about-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 2em;
  text-shadow: 5px 3px 6px #0f416399;
  max-width: 100%;
  line-height: 1.1;
  letter-spacing: 0.5px;
}

.about-title span {
  color: #22a0f3;
}
.about-image {
  flex: 1;
  text-align: center;
}

.about__image {
  height: 250px;
  width: 250px;

  border-radius: 50%;
  box-shadow: 3px 3px 10px #192e42;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.about-contacts {
  text-align: left;
  padding: 15px;
  bottom: 0;
  flex: 1;
  font-size: 25px;
}

.about-body {
  font-size: 100%;
  line-height: 1.3;
  letter-spacing: 1.5px;
}

.firma {
  margin-bottom: 15px;
  color: #22a0f3;
}

.phone {
  margin-bottom: 10px;
}
.phone-icon {
  color: #22a0f3;
  padding: 5px;
  vertical-align: middle;
}
.envelope {
  margin-bottom: 10px;
}

.envelope-icon {
  color: #22a0f3;
  vertical-align: middle;
  padding: 5px;
}
.location {
  padding-top: 20px;
}
.location-icon {
  color: rgb(219, 24, 40);
  padding: 5px;
  margin-right: 10px;
}
.square1 {
  position: absolute;
  bottom: -140px;
  left: 60px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 3px solid #1f4871;
  transform: scale(1.3) translateY(-90px) rotate(360deg);
  -webkit-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -moz-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -ms-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -o-transform: scale(1.3) translateY(-90px) rotate(360deg);
  animation: square 3s ease-in-out infinite;
  -webkit-animation: square 3s ease-in-out infinite;
  animation-direction: alternate;
}

@-webkit-keyframes square {
  0% {
    border-color: #1f476e;
  }

  100% {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

.square2 {
  position: absolute;
  bottom: -120px;
  left: 80px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 4px solid rgba(255, 255, 255, 0.8);
  transform: scale(1.3) translateY(-90px) rotate(360deg);
  -webkit-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -moz-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -ms-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -o-transform: scale(1.3) translateY(-90px) rotate(360deg);
  animation: square2 3s ease-in-out infinite;
  -webkit-animation: square2 3s ease-in-out infinite;
  animation-direction: alternate;
}

@-webkit-keyframes square2 {
  0% {
    border-color: rgba(255, 255, 255, 0.8);
  }

  100% {
    border-color: #1f476e;
  }
}
@media (max-width: 570px) {
  .about-block {
    height: initial;
    padding: 50px 0;
    margin-top: 120px;
  }

  .about-contacts {
    text-align: center;
    font-size: 23px;
  }
}
.about__image {
  height: 200px;
  width: 200px;
}

@media (max-width: 415px) {
  .about-body {
    font-size: 20px;
    padding: 10px px;
    margin-left: -8px;
  }
}

@media (max-width: 500px) {
  .services-block {
    margin-bottom: -20px;
  }

  .square1 {
    width: 20px;
    height: 20px;
    border: 2px solid;
    bottom: -115px;
    left: 30px;
  }

  .square2 {
    width: 30px;
    height: 30px;
    border: 3px solid;
    bottom: -100px;
    left: 46px;
  }
}
</style>
