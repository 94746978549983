<template>
  <h2>Kaabeldus tööd</h2>
  <p>Pakume teenuseid telefoniliinide ja Interneti-ühenduste paigaldamiseks.</p>
  <p>Kasutame kaabli tüübid:</p>
  <ul style="list-style-type: square">
    <li><p>CAT 5e</p></li>
    <li><p>CAT 6</p></li>
    <li><p>CAT 6a</p></li>
  </ul>
</template>

<script>
export default {};
</script>

<style scoped>
h2 {
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 150%;
  color: #22a0f3;
}

p {
  text-align: start;
  padding: 1rem;
  font-style: normal;
  font-size: 110%;
  line-height: 1.5;
}

a {
  color: #1e73be;
}

ul {
  padding: 0.5rem 0 1rem 3rem;
  margin-left: 1em;
}
</style>
