import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
/* import specific icons */

/* add icons to the library */
library.add(fas);

createApp(App).use(router).component("fa", FontAwesomeIcon).mount("#app");
