<template>
  <carousel @next="next" @prev="prev">
    <carousel-slide
      v-for="(slide, index) in slides"
      :key="slide"
      :index="index"
      :visibleSlide="visibleSlide"
      class="slider-image"
    >
      <img :src="slide.src" />
    </carousel-slide>
  </carousel>
</template>

<script>
import Carousel from "./Carousel.vue";
import CarouselSlide from "./CarouselSlide.vue";
export default {
  data() {
    return {
      slides: [
        { src: "/images/ourwork/3.jpg" },
        { src: "/images/ourwork/1.jpg" },
        { src: "/images/ourwork/2.jpg" },
        { src: "/images/ourwork/4.jpg" },
      ],
      visibleSlide: 0,
    };
  },
  components: { CarouselSlide, Carousel },
  computed: {
    slidesLength() {
      return this.slides.length;
    },
  },
  methods: {
    next() {
      if (this.visibleSlide >= this.slidesLength - 1) {
        this.visibleSlide = 0;
      } else {
        this.visibleSlide++;
      }
    },
    prev() {
      if (this.visibleSlide <= 0) {
        this.visibleSlide = this.slidesLength - 1;
      } else {
        this.visibleSlide--;
      }
    },
  },
};
</script>

<style scoped>
.slider-image img {
  width: 100%;
  height: 100%;
}
</style>
