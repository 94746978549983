<template>
  <footer>
    © {{ getYear }} SmartIT – All rights reserved | Designed by Tatjana
    Platonova
  </footer>
</template>

<script>
  export default {
    computed: {
      getYear() {
        return new Date().getFullYear();
      },
    },
  };
</script>
<style scoped>
  footer {
    background-color: #333;
    text-align: center;
    padding: 15px;
    color: #22a0f3;
    opacity: 0.7;
  }
</style>
