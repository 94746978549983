<template>
  <div id="app">
    <div class="fullscreen">
      <div class="fullscreen-body">
        <NavbarMenu />
        <router-view />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavbarMenu from "./NavbarMenu.vue";
import Footer from "./Footer.vue";

export default {
  components: {
    NavbarMenu,
    Footer,
  },
  data() {},
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

html,
body {
  font-family: "Roboto", sans-serif;
  height: 100%;
  background-color: rgb(11, 10, 10);
  color: rgb(245, 240, 240);
  font-size: 15px;

  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "Raleway", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: rgb(245, 240, 240);
}

a:hover {
  color: #22a0f3;
}

.fullscreen {
  background: url(/images/bg.jpg) center no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
}

.fullscreen-body {
  display: flex;
  flex: 1 1 auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  letter-spacing: 3px;
}
</style>
