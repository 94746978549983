<template>
  <div class="header">
    <div class="container">
      <div class="header-inner">
        <router-link to="/" class="header-logo">
          <img src="/images/SmartIT3.png" alt="" class="header-logo" />
        </router-link>

        <nav>
          <div class="header-menu">
            <router-link class="nav-link" to="/"><a>Esileht</a></router-link>
            <router-link class="nav-link" to="/services">Teenused</router-link>
            <router-link class="nav-link" to="/portfolio"
              >Tehtud Tööd</router-link
            >
            <router-link class="nav-link" to="/contact"> Kontakt </router-link>
          </div>
          <div class="header-burger">
            <span class="lines line-1"></span>
            <span class="lines line-2"></span>
            <span class="lines line-3"></span>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { navSlide } from "./burgerMenu";
export default {
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    navSlide();
  },
};
</script>

<style>
.header {
  position: fixed;
  height: 90px;
  top: 0;
  z-index: 50;
  width: 100%;
  transition: 0.3s;
  padding-bottom: 10px;
  background-color: rgba(36, 34, 34, 0.647);
}

.header:hover {
  background-color: rgb(36, 34, 34);
  box-shadow: 3px 3px 3px #164779;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header-logo img {
  flex: 0 0 33%;
  width: 200px;
}

nav {
  display: block;
}

.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-link {
  font-style: normal;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  text-align: center;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
  padding: 0.5rem;
  margin-right: 25px;
  color: #fff;
  text-transform: uppercase;
  transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  position: relative;
}

.nav-link:last-child {
  margin: 0;
}

.nav-link::after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background-color: #2270be;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  margin-top: -3px;
  opacity: 0;
  transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
}

.nav-link:hover::after {
  opacity: 1;
}

.nav-link:hover,
.nav-link:active {
  color: #22a0f3;
}

.header-burger {
  opacity: 0;
  cursor: pointer;
  border: 3px solid #22a0f3;
  width: 50px;
  height: 50px;
  background: transparent;
  position: absolute;
  margin-top: 6px;
  right: 25px;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.lines {
  width: 25px;
  height: 3px;

  background: #ffffff;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;

  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
  -webkit-transform: translate3d(0, -8px, 0);
  transform: translate3d(0, -8px, 0);
}

.line-2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.line-3 {
  -webkit-transform: translate3d(0, 8px, 0);
  transform: translate3d(0, 8px, 0);
}

/*MEDIA*/

@media screen and (max-width: 1000px) {
  body {
    overflow-x: hidden;
  }

  .header-burger {
    opacity: 1;
  }

  .header-menu {
    position: absolute;
    right: -30%;
    height: 100vh;
    top: 92px;
    background-color: rgb(36, 34, 34);
    box-shadow: -3px -3px 3px #164779;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    width: 30%;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transition: transform 0.5s ease-in;
    -webkit-transition: transform 0.5s ease-in;
    -moz-transition: transform 0.5s ease-in;
    -ms-transition: transform 0.5s ease-in;
    -o-transition: transform 0.5s ease-in;
  }

  .nav-link {
    opacity: 0;
    margin-right: 0;
    margin-bottom: -100px;
  }
  .nav-link:last-child {
    align-self: center;
    margin: 15px 0 200px;
  }

  .header-logo img {
    position: absolute;
    left: 15px;
    top: 15px;
  }
}
@media (max-width: 724px) {
  .header {
    height: 90px;
    background-color: rgb(36, 34, 34);
    box-shadow: 3px 3px 3px #164779;
  }

  .header-menu {
    right: -40%;
    width: 40%;
    top: 94px;
  }

  .header-burger {
    right: 20;
  }

  .header-logo img {
    flex: 0 0 60px;
    max-width: 200px;
  }
}

@media (max-width: 517px) {
  .header {
    height: 70px;
  }

  .header-menu {
    background-color: rgb(26, 44, 70);
    right: -100%;
    top: 70px;
    width: 100%;
  }

  .header-burger {
    border: 0;
    top: 5px;
    right: 5px;
  }

  .header-logo img {
    flex: 0 0 60px;
    width: 35%;
    top: 10px;
    left: 5px;
    z-index: 10;
  }
}

@media (max-width: 330px) {
  .header {
    height: 65px;
  }

  .header-burger {
    border: 0;
    top: 0;
    right: 0;
  }

  .header-logo img {
    flex: 0 0 60px;
    width: 40%;
    top: 10px;
    left: 8px;
  }
}
@media screen and (max-width: 653px), screen and (max-height: 280px) {
  .nav-link:last-child {
    margin: 1px 0 200px;
  }
  .nav-link:first-child {
    margin-top: 10px;
  }
}
.menu-active {
  /* overflow: auto; */
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}

@-webkit-keyframes navLinksFade {
  from {
    opacity: 0;
    transform: translatex(50px);
    -webkit-transform: translatex(50px);
    -moz-transform: translatex(50px);
    -ms-transform: translatex(50px);
    -o-transform: translatex(50px);
  }
  to {
    opacity: 1;
    transform: translatex(0px);
    -webkit-transform: translatex(0px);
    -moz-transform: translatex(0px);
    -ms-transform: translatex(0px);
    -o-transform: translatex(0px);
  }
}

.toggle .line-1 {
  transform-origin: center;
  -webkit-transform-origin: center;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.toggle .line-2 {
  opacity: 0;
}

.toggle .line-3 {
  transform-origin: center;
  -webkit-transform-origin: center;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
</style>
