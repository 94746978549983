<template>
  <div class="container">
    <div class="tabs-wrap">
      <button
        :class="['tab-btn', { active: selected === tab }]"
        v-for="tab in tabs"
        :key="tab"
        @click="selected = tab"
      >
        {{ tab }}
      </button>
    </div>
    <div class="description-body">
      <router-link class="nav-link" to="/services"
        ><fa icon="fa-arrow-left" class="arrow" />Tagasi
      </router-link>
      <component :is="selected"> </component>
      <p class="portfolio-link">
        Vaata meie tööd
        <router-link to="/portfolio">siit</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import WiFi from "./WiFi.vue";
import Kaabeldus from "./Kaabeldus.vue";

export default {
  components: { WiFi, Kaabeldus },
  data() {
    return {
      tabs: ["WiFi", "Kaabeldus"],
      selected: "WiFi",
    };
  },
};
</script>

<style scoped>
.portfolio-link {
  text-align: center;
  font-size: 120%;
  font-style: italic;
  position: relative;
  font-weight: 600;
}

.portfolio-link::after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background-color: #2270be;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 1;
  z-index: 2;
  margin-top: 3px;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(28, 28, 28);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #2425269e;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1f4871;
}

.nav-link {
  font-style: italic;
  font-size: 80%;
  margin-bottom: 15px;
  padding: 5px;
  opacity: 1;
  display: block;
  width: 100px;
  font-weight: normal;
}

.nav-link::after {
  width: 80px;
  margin-top: 3px;
  margin-left: 10px;
}
.arrow {
  margin-right: 5px;
}

.description-body {
  padding: 20px;
  color: rgb(238, 231, 231);
  background-color: rgba(36, 34, 34, 0.54);
  box-shadow: 3px 3px 3px #1f4871;
  overflow-y: auto;
  height: 600px;
  position: relative;
  transition: 0.3s;
}

.description-body:hover {
  background-color: rgb(36, 34, 34);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

.tabs-wrap {
  display: flex;
  margin-top: 100px;
}

.tab-btn {
  flex-direction: row;
  background: none;
  border: 2px solid #fff;
  padding: 10px 15px;

  font-size: 100%;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 600;
  transition: background 0.2s linear;
  cursor: pointer;
}

.active {
  color: #333;
  background: #22a0f3;
}

@media (max-width: 325px) {
  .tab-btn {
    font-weight: 400;
    padding: 6px 10px;
  }

  .description-body {
    padding: 3px;
    height: 400px;
  }
}
</style>
