<template>
  <div class="services-block">
    <div class="square1"></div>
    <div class="square2"></div>
    <div class="container">
      <div class="text-inner">
        <div class="text-title">Teenused</div>
        <div class="text-subtitle">Nutilahendused teile!</div>
      </div>

      <div class="services__row">
        <div v-for="item in items" :key="item.id" class="services__item">
          <router-link :to="item.link">
            <div class="services-image">
              <img :src="item.image" :alt="item.alt" />
            </div>
            <h1 class="services__text">
              {{ item.title }}
            </h1>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Interaktiivne tahvel paigaldamine",
          image:
            "https://media.istockphoto.com/photos/spanish-girl-interacting-with-digital-chalkboard-at-classroom-picture-id1337471469?b=1&k=20&m=1337471469&s=612x612&w=0&h=GsD6FR4HpCCLQXuNUEJq-q75kHt4IEd2-oWzzn2CVx0=",
          alt: "board",
          link: "/board",
        },
        {
          id: 2,
          title: "Tarkvara paigaldamine",
          image:
            "https://media.istockphoto.com/photos/automation-and-optimisation-concept-business-process-workflow-picture-id1212102699?k=20&m=1212102699&s=612x612&w=0&h=76QRR-oxIzNDigLYKG1ZSFGjqhN6muXCnLHM9fmcEh4=",
          alt: "software",
          link: "./software",
        },
        {
          id: 3,
          title: "Kaabeldus tööd/WiFi",
          image:
            "https://media.istockphoto.com/photos/home-network-switch-with-free-dsl-port-picture-id157617773?k=20&m=157617773&s=612x612&w=0&h=efr4KZSvwpFRuHMQ5xOqKYRsqE2_TCY2m2ylKX5slYs=",
          alt: "network",
          link: "/network",
        },
      ],
    };
  },
};
</script>

<style scoped>
.services-block {
  margin: 250px 0;
  background-color: rgba(36, 34, 34, 0.647);
  box-shadow: 3px 3px 3px #1f4871;
  padding: 70px 0;
  position: relative;
  height: 550px;
  transition: 0.3s;
}

.services-block:hover {
  background-color: rgb(36, 34, 34);
}

.text-inner {
  text-align: center;
  text-transform: uppercase;
}

.text-title {
  font-size: 50px;
  font-weight: 700;
  color: #22a0f3;
  font-style: italic;
  /* text-shadow: 3px 3px 3px #1c0953; */
}

.text-title::after {
  content: "";
  display: block;
  height: 3px;
  width: 60px;
  background-color: #ffffff;
  margin: 25px auto;
}

.text-subtitle {
  padding-bottom: 30px;
  font-size: 20px;
  color: #ffffff;
  opacity: 0.9;
  letter-spacing: 2px;
  font-style: italic;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  overflow: hidden;
}

.services__row {
  display: flex;
  margin: 0 -15px;
}

.services__item {
  cursor: pointer;
  flex: 1 1 33.333%;
  padding: 0 15px;
  text-align: center;
  position: relative;
}

.services-image {
  background-color: #1f4871;
  box-shadow: 3px 3px 10px #1f4871;
}
.services-image img {
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services__text {
  font-style: italic;
  opacity: 0;
  width: 90%;
  line-height: 1.5;
  color: #fff;
  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  z-index: 3;
  text-align: center;
  transform: translate3d(0, -50%, 0);
}

.services__item:hover .services__text {
  opacity: 1;
}

.services__item:hover .services-image img {
  opacity: 0.4;
}

@media (max-width: 700px) {
  .services-block {
    height: initial;
    padding-top: 50px;
    margin-bottom: -300px;
  }

  .services__text {
    opacity: 1;
  }

  .services__row {
    flex-direction: column;
    align-items: center;
  }

  .services__item {
    width: 70%;
    margin-bottom: 15px;
  }

  .services__text {
    font-size: 15px;
  }

  .text-title {
    font-size: 30px;
  }

  .text-title::after {
    margin: 25px auto;
  }

  .text-subtitle {
    font-size: 15px;
    line-height: 1.2;
  }
}

.square1 {
  position: absolute;
  bottom: -140px;
  left: 60px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 3px solid #1f4871;
  transform: scale(1.3) translateY(-90px) rotate(360deg);
  -webkit-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -moz-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -ms-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -o-transform: scale(1.3) translateY(-90px) rotate(360deg);
  animation: square 3s ease-in-out infinite;
  -webkit-animation: square 3s ease-in-out infinite;
  animation-direction: alternate;
}

@keyframes square {
  0% {
    border-color: #1f476e;
  }

  100% {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

.square2 {
  position: absolute;
  bottom: -120px;
  left: 80px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 4px solid rgba(255, 255, 255, 0.8);
  transform: scale(1.3) translateY(-90px) rotate(360deg);
  -webkit-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -moz-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -ms-transform: scale(1.3) translateY(-90px) rotate(360deg);
  -o-transform: scale(1.3) translateY(-90px) rotate(360deg);
  animation: square2 3s ease-in-out infinite;
  -webkit-animation: square2 3s ease-in-out infinite;
  animation-direction: alternate;
}

@keyframes square2 {
  0% {
    border-color: rgba(255, 255, 255, 0.8);
  }

  100% {
    border-color: #1f476e;
  }
}

@media (max-width: 500px) {
  .services-block {
    margin-bottom: -20px;
  }

  .square1 {
    width: 20px;
    height: 20px;
    border: 2px solid;
    bottom: -115px;
    left: 30px;
  }

  .square2 {
    width: 30px;
    height: 30px;
    border: 3px solid;
    bottom: -100px;
    left: 46px;
  }
}
</style>
