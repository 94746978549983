<template>
  <h1 class="error">404</h1>
  <h1>PAGE NOT FOUND</h1>
  <!-- <img
    src="https://res.cloudinary.com/practicaldev/image/fetch/s--jn8bC0GO--/c_imagga_scale,f_auto,fl_progressive,h_720,q_auto,w_1280/https://dev-to-uploads.s3.amazonaws.com/i/00orwz3b4rfsc3uw1vj0.png"
    alt=""
  /> -->
</template>

<script>
export default {};
</script>

<style scoped>
.error {
  color: rgb(247, 247, 247);
  text-shadow: 3px 3px 3px #164779;
  font-size: 900%;
  margin-bottom: 2%;
}
</style>
