<template>
  <div class="container-carousel">
    <div class="carousel">
      <button @click="prev" class="prev"><fa icon="arrow-left" /></button>
      <button @click="next" class="next">
        <fa icon="arrow-right" />
      </button>
      <div class="carousel-slide">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$emit("next");
    },
    prev() {
      this.$emit("prev");
    },
  },
};
</script>

<style scoped>
.container-carousel {
  margin-left: 3%;
  margin-right: 5%;
  margin-top: 5%;
}
.carousel {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  max-width: 590px;
  max-height: 786px;
  box-shadow: 3px 3px 3px #164779;
}

button {
  position: absolute;
  width: 60px;
  height: 30px;
  top: calc(50% - 20px);
  color: rgb(247, 247, 247);
  background-color: rgb(36, 34, 34);
  box-shadow: 3px 3px 3px #164779;
  border: none;
}
button:active,
button:hover {
  cursor: pointer;
  background-color: #0d75dd;
  outline-color: rgb(36, 34, 34);
}
.next {
  right: 0;
}
.prev {
  left: 0;
}

@media (max-width: 591px) {
  .container-carousel {
    margin-top: 15%;
  }
  button {
    width: 40px;
    height: 20px;
  }
}
</style>
