export const navSlide = function () {
  const burgerMenu = document.querySelector(".header-burger");
  const headerMenu = document.querySelector(".header-menu");
  const navLinks = document.querySelectorAll(".nav-link");
  let isMenuOpen = false;

  function setMenuItemAnimation(enabled) {
    navLinks.forEach(function (link, index) {
      if (enabled) {
        link.style.animation = `navLinksFade 0.5s ease forwards ${
          index / 7 + 0.5
        }s`;
      } else {
        link.style.animation = "";
      }
    });
  }

  function openMenu() {
    if (!isMenuOpen) {
      isMenuOpen = true;

      // Enable extra animations when opening!
      setMenuItemAnimation(true);

      // Open menu
      headerMenu.classList.add("menu-active");
      burgerMenu.classList.add("toggle");
    }
  }

  function closeMenu() {
    if (isMenuOpen) {
      isMenuOpen = false;

      // No extra animation of menu items when closing
      setMenuItemAnimation(false);

      // Close menu
      headerMenu.classList.remove("menu-active");
      burgerMenu.classList.remove("toggle");
    }
  }

  window.addEventListener("resize", () => {
    const menuHidden = window.innerWidth > 1000;

    // Debug logging to see what's happening
    //console.log(`Width: ${window.innerWidth} Hidden menu: ${menuHidden}`)

    if (isMenuOpen && menuHidden) {
      closeMenu(); // Close the menu when it's hidden due to a wide window
    }
  });

  burgerMenu.addEventListener("click", function () {
    if (isMenuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  navLinks.forEach((navLink) => {
    navLink.addEventListener("click", () => {
      closeMenu();
    });
  });
};
document.addEventListener("DOMContentLoaded", navSlide);
